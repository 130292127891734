import React from 'react';
import  Archive2023  from '../views/Archive2023';
import { GalleryImages2023 } from '../views/Archive2023/components';

const BlogNewsroomPage = () => {

return <GalleryImages2023/>
};

export default BlogNewsroomPage;

