import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Container from 'components/Container';

const GalleryImages2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_archive_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/1.webp`;
const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/2.webp`;
const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/3.webp`;
const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/4.webp`;
const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/5.webp`;
const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/6.webp`;
const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/7.webp`;
const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/8.webp`;
const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/9.webp`;
const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/10.webp`;
const p11 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/11.webp`;
const p12 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/12.webp`;
const p13 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/13.webp`;
const p14 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/14.webp`;
const p15 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/15.webp`;
const p16 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/16.webp`;
const p17 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/17.webp`;
const p18 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/18.webp`;
const p19 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/19.webp`;
const p20 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/20.webp`;
const p21 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/21.webp`;
const p22 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/22.webp`;
const p23 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/23.webp`;
const p24 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/24.webp`;
const p25 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/25.webp`;
const p26 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/26.webp`;
const p27 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/27.webp`;
const p28 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/28.webp`;
const p29 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/29.webp`;
const p30 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/30.webp`;
const p31 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/31.webp`;
const p32 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/32.webp`;
// const p33 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/33.webp`;
const p34 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/34.webp`;
const p35 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/35.webp`;
const p36 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/36.webp`;
const p37 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/37.webp`;
const p38 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/38.webp`;
const p39 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/39.webp`;
const p40 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/40.webp`;
const p41 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/41.webp`;
const p42 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/42.webp`;
const p43 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/43.webp`;
const p44 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/44.webp`;
const p45 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/45.webp`;
const p46 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/46.webp`;
const p47 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/47.webp`;
const p48 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/48.webp`;
const p49 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/49.webp`;
const p50 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/50.webp`;
const p51 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/51.webp`;
const p52 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/52.webp`;
const p53 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/53.webp`;
const p54 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/54.webp`;
const p55 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/55.webp`;
const p56 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/56.webp`;
const p57 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/57.webp`;
const p58 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/58.webp`;
const p59 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/59.webp`;
const p60 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/60.webp`;
const p61 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/61.webp`;
const p62 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/62.webp`;
const p63 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/63.webp`;
const p64 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/64.webp`;
const p65 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/65.webp`;
const p66 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/66.webp`;
const p67 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/67.webp`;
const p68 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/68.webp`;
const p69 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/69.webp`;
const p70 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/70.webp`;
const p71 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/71.webp`;
const p72 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/72.webp`;
const p73 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/73.webp`;
const p74 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/74.webp`;
const p75 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/75.webp`;
const p76 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/76.webp`;
const p77 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/77.webp`;
const p78 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/78.webp`;
const p79 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/79.webp`;
const p80 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/80.webp`;
const p81 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/81.webp`;
const p82 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/82.webp`;
const p83 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/83.webp`;
const p84 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/84.webp`;
const p85 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/85.webp`;
const p86 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/86.webp`;
const p87 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/87.webp`;
const p88 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/88.webp`;
const p89 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/89.webp`;
const p90 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/90.webp`;
const p91 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/91.webp`;
const p92 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/92.webp`;
const p93 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/93.webp`;
const p94 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/94.webp`;
const p95 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/95.webp`;
const p96 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/96.webp`;
const p97 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/97.webp`;
const p98 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/98.webp`;
const p99 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/99.webp`;
const p100 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/100.webp`;
const p101 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/101.webp`;
const p102 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/102.webp`;
const p103 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/103.webp`;
const p104 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/104.webp`;
const p105 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/105.webp`;
const p106 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/106.webp`;
const p107 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/107.webp`;
const p108 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/108.webp`;
const p109 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/109.webp`;
const p110 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/110.webp`;
const p111 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/111.webp`;
const p112 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/112.webp`;
const p113 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/113.webp`;
const p114 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/114.webp`;
const p115 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/115.webp`;
const p116 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/116.webp`;
const p117 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/117.webp`;
const p118 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/118.webp`;
const p119 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/119.webp`;
const p120 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/120.webp`;
const p121 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/121.webp`;
const p122 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/122.webp`;
const p123 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/123.webp`;
const p124 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/124.webp`;
const p125 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/125.webp`;
const p126 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/126.webp`;
const p127 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/127.webp`;
const p128 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/128.webp`;
const p129 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/129.webp`;
const p130 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/130.webp`;
const p131 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/131.webp`;
const p132 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/132.webp`;
const p133 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/133.webp`;
const p134 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/134.webp`;
const p135 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/135.webp`;
const p136 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/136.webp`;
const p137 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/137.webp`;
const p138 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/138.webp`;
const p139 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/139.webp`;
const p140 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/140.webp`;
const p141 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/141.webp`;
const p142 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/142.webp`;
const p143 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/143.webp`;
const p144 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/144.webp`;
const p145 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/145.webp`;
const p146 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/146.webp`;
const p147 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/147.webp`;
const p148 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/148.webp`;
const p149 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/149.webp`;
const p150 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/150.webp`;
const p151 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/151.webp`;
const p152 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/152.webp`;
const p153 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/153.webp`;
const p154 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/154.webp`;
const p155 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/155.webp`;
const p156 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/156.webp`;
const p157 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/157.webp`;
const p158 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/158.webp`;
const p159 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/159.webp`;
const p160 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/160.webp`;
const p161 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/161.webp`;
const p162 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/162.webp`;
const p163 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/163.webp`;
const p164 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/164.webp`;
const p165 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/165.webp`;
const p166 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/166.webp`;
const p167 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/167.webp`;
const p168 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/168.webp`;
const p169 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/169.webp`;
const p170 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/170.webp`;
const p171 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/171.webp`;
const p172 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/172.webp`;
const p173 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/173.webp`;
const p174 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/174.webp`;
const p175 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/175.webp`;
const p176 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/176.webp`;
const p177 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/177.webp`;
const p178 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/178.webp`;
const p179 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/179.webp`;
const p180 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/180.webp`;
const p181 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/181.webp`;
const p182 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/182.webp`;
const p183 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/183.webp`;
const p184 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/184.webp`;
const p185 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/185.webp`;
const p186 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/186.webp`;
const p187 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/187.webp`;
const p188 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/188.webp`;
const p189 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/189.webp`;
const p190 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/190.webp`;
const p191 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/191.webp`;
const p192 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/192.webp`;
const p193 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/193.webp`;
const p194 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/194.webp`;
const p195 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/195.webp`;
const p196 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/196.webp`;
const p197 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/197.webp`;
const p198 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/198.webp`;
const p199 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/199.webp`;
const p200 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/200.webp`;
const p201 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/201.webp`;
const p202 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/202.webp`;
const p203 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/203.webp`;
const p204 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/204.webp`;
const p205 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/205.webp`;
const p206 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/206.webp`;
const p207 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/207.webp`;
const p208 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/208.webp`;
const p209 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/209.webp`;
const p210 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/210.webp`;
const p211 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/211.webp`;
const p212 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/212.webp`;
const p213 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/213.webp`;
const p214 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/214.webp`;
const p215 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/215.webp`;
const p216 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/216.webp`;
const p217 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/217.webp`;
const p218 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/218.webp`;
const p219 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/219.webp`;
const p220 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/220.webp`;
const p221 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/221.webp`;
const p222 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/222.webp`;
const p223 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/223.webp`;
const p224 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/224.webp`;
const p225 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/225.webp`;
const p226 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/226.webp`;
const p227 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/227.webp`;
const p228 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/228.webp`;
const p229 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/229.webp`;
const p230 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/230.webp`;
const p231 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/231.webp`;
const p232 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/232.webp`;
const p233 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/233.webp`;
const p234 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/234.webp`;
const p235 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/235.webp`;
const p236 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/236.webp`;
const p237 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/237.webp`;
const p238 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/238.webp`;
const p239 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/239.webp`;
const p240 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/240.webp`;
const p241 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/241.webp`;
const p242 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/242.webp`;
const p243 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/243.webp`;
const p244 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/244.webp`;
const p245 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/245.webp`;
const p246 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/246.webp`;
const p247 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/247.webp`;
const p248 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/248.webp`;
const p249 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/249.webp`;
const p250 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/250.webp`;
const p251 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/251.webp`;
const p252 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/252.webp`;
const p253 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/253.webp`;
const p254 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/254.webp`;
const p255 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/255.webp`;
const p256 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/256.webp`;
const p257 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/257.webp`;
const p258 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/258.webp`;
const p259 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/259.webp`;
const p260 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/260.webp`;
const p261 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/261.webp`;
const p262 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/262.webp`;
const p263 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/263.webp`;
const p264 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/264.webp`;
const p265 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/265.webp`;
const p266 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/266.webp`;
const p267 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/267.webp`;
const p268 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/268.webp`;
const p269 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/269.webp`;
const p270 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/270.webp`;
const p271 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/271.webp`;
const p272 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/272.webp`;
const p273 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/273.webp`;
const p274 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/274.webp`;
const p275 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/275.webp`;
const p276 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/276.webp`;
const p277 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/277.webp`;
const p278 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/278.webp`;
const p279 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/279.webp`;
const p280 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/280.webp`;
const p281 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/281.webp`;
const p282 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/282.webp`;
const p283 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/283.webp`;
const p284 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/284.webp`;
const p285 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/285.webp`;
const p286 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/286.webp`;
const p287 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/287.webp`;
const p288 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/288.webp`;
const p289 = `${config.base_image_archive_url}/academic-year-2023-24/home/home-static/our-campus/photo-gallery-2023/289.webp`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source: p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p5,
      source: p5,
      rows: 1,
      cols: 1,
    },
    {
      src: p6,
      source: p6,
      rows: 1,
      cols: 1,
    },
    {
      src: p7,
      source: p7,
      rows: 1,
      cols: 1,
    },
    {
      src: p8,
      source: p8,
      rows: 1,
      cols: 1,
    },
    {
      src: p9,
      source: p9,
      rows: 1,
      cols: 1,
    },
    {
      src: p10,
      source: p10,
      rows: 1,
      cols: 1,
    }, {
      src: p11,
      source: p11,
      rows: 1,
      cols: 1,
    },
    {
      src: p12,
      source: p12,
      rows: 1,
      cols: 1,
    },
    {
      src: p13,
      source: p13,
      rows: 1,
      cols: 1,
    },
    {
      src: p14,
      source: p14,
      rows: 1,
      cols: 1,
    },
    {
      src: p15,
      source: p15,
      rows: 1,
      cols: 1,
    },
    {
      src: p16,
      source: p16,
      rows: 1,
      cols: 1,
    },
    {
      src: p17,
      source: p17,
      rows: 1,
      cols: 1,
    },
    {
      src: p18,
      source: p18,
      rows: 1,
      cols: 1,
    },
    {
      src: p19,
      source: p19,
      rows: 1,
      cols: 1,
    },
    {
      src: p20,
      source: p20,
      rows: 1,
      cols: 1,
    },
    {
      src: p21,
      source: p21,
      rows: 1,
      cols: 1,
    },
    {
      src: p22,
      source: p22,
      rows: 1,
      cols: 1,
    },
    {
      src: p23,
      source: p23,
      rows: 1,
      cols: 1,
    },
    {
      src: p24,
      source: p24,
      rows: 1,
      cols: 1,
    },
    {
      src: p25,
      source: p25,
      rows: 1,
      cols: 1,
    },
    {
      src: p26,
      source: p26,
      rows: 1,
      cols: 1,
    },
    {
      src: p27,
      source: p27,
      rows: 1,
      cols: 1,
    },
    {
      src: p28,
      source: p28,
      rows: 1,
      cols: 1,
    },
    {
      src: p29,
      source: p29,
      rows: 1,
      cols: 1,
    },
    {
      src: p30,
      source: p30,
      rows: 1,
      cols: 1,
    },
    {
      src: p31,
      source: p31,
      rows: 1,
      cols: 1,
    },
    {
      src: p32,
      source: p32,
      rows: 1,
      cols: 1,
    }, {
      src: p34,
      source: p34,
      rows: 1,
      cols: 1,
    },
    {
      src: p35,
      source: p35,
      rows: 1,
      cols: 1,
    },
    {
      src: p36,
      source: p36,
      rows: 1,
      cols: 1,
    },
    {
      src: p37,
      source: p37,
      rows: 1,
      cols: 1,
    },
    {
      src: p38,
      source: p38,
      rows: 1,
      cols: 1,
    },
    {
      src: p39,
      source: p39,
      rows: 1,
      cols: 1,
    },
    {
      src: p40,
      source: p40,
      rows: 1,
      cols: 1,
    },
    {
      src: p41,
      source: p41,
      rows: 1,
      cols: 1,
    },
    {
      src: p42,
      source: p42,
      rows: 1,
      cols: 1,
    },
    {
      src: p43,
      source: p43,
      rows: 1,
      cols: 1,
    },
    {
      src: p44,
      source: p44,
      rows: 1,
      cols: 1,
    },
    {
      src: p45,
      source: p45,
      rows: 1,
      cols: 1,
    },
    {
      src: p46,
      source: p46,
      rows: 1,
      cols: 1,
    },
    {
      src: p47,
      source: p47,
      rows: 1,
      cols: 1,
    },
    {
      src: p48,
      source: p48,
      rows: 1,
      cols: 1,
    },
    {
      src: p49,
      source: p49,
      rows: 1,
      cols: 1,
    },
    {
      src: p50,
      source: p50,
      rows: 1,
      cols: 1,
    },
    {
      src: p51,
      source: p51,
      rows: 1,
      cols: 1,
    },
    {
      src: p52,
      source: p52,
      rows: 1,
      cols: 1,
    },
    {
      src: p53,
      source: p53,
      rows: 1,
      cols: 1,
    },
    {
      src: p54,
      source: p54,
      rows: 1,
      cols: 1,
    },
    {
      src: p55,
      source: p55,
      rows: 1,
      cols: 1,
    }, {
      src: p56,
      source: p56,
      rows: 1,
      cols: 1,
    },
    {
      src: p57,
      source: p57,
      rows: 1,
      cols: 1,
    },
    {
      src: p58,
      source: p58,
      rows: 1,
      cols: 1,
    },
    {
      src: p59,
      source: p59,
      rows: 1,
      cols: 1,
    },
    {
          src: p60,
          source: p60,
          rows: 1,
          cols: 1,
        },
        {
          src: p61,
          source: p61,
          rows: 1,
          cols: 1,
        },
        {
          src: p62,
          source: p62,
          rows: 1,
          cols: 1,
        },
        {
          src: p63,
          source: p63,
          rows: 1,
          cols: 1,
        },
        {
          src: p64,
          source: p64,
          rows: 1,
          cols: 1,
        },
        {
          src: p65,
          source: p65,
          rows: 1,
          cols: 1,
        },
        {
          src: p66,
          source: p66,
          rows: 1,
          cols: 1,
        },
        {
          src: p67,
          source: p67,
          rows: 1,
          cols: 1,
        },
        {
          src: p68,
          source: p68,
          rows: 1,
          cols: 1,
        },
        {
          src: p69,
          source: p69,
          rows: 1,
          cols: 1,
        }, {
          src: p70,
          source: p70,
          rows: 1,
          cols: 1,
        },
        {
          src: p71,
          source: p71,
          rows: 1,
          cols: 1,
        },
        {
          src: p72,
          source: p72,
          rows: 1,
          cols: 1,
        },
        {
          src: p73,
          source: p73,
          rows: 1,
          cols: 1,
        },
        {
          src: p74,
          source: p74,
          rows: 1,
          cols: 1,
        },
        {
          src: p75,
          source: p75,
          rows: 1,
          cols: 1,
        },
        {
          src: p76,
          source: p76,
          rows: 1,
          cols: 1,
        },
        {
          src: p77,
          source: p77,
          rows: 1,
          cols: 1,
        },
        {
          src: p78,
          source: p78,
          rows: 1,
          cols: 1,
        },
        {
          src: p79,
          source: p79,
          rows: 1,
          cols: 1,
        },
        {
          src: p80,
          source: p80,
          rows: 1,
          cols: 1,
        },
        {
          src: p81,
          source: p81,
          rows: 1,
          cols: 1,
        },
        {
          src: p82,
          source: p82,
          rows: 1,
          cols: 1,
        },
        {
          src: p83,
          source: p83,
          rows: 1,
          cols: 1,
        },
        {
          src: p84,
          source: p84,
          rows: 1,
          cols: 1,
        },
        {
          src: p85,
          source: p85,
          rows: 1,
          cols: 1,
        },
        {
          src: p86,
          source: p86,
          rows: 1,
          cols: 1,
        },
        {
          src: p87,
          source: p87,
          rows: 1,
          cols: 1,
        },
        {
          src: p88,
          source: p88,
          rows: 1,
          cols: 1,
        },
        {
          src: p89,
          source: p89,
          rows: 1,
          cols: 1,
        },
        {
          src: p90,
          source: p90,
          rows: 1,
          cols: 1,
        },
        {
          src: p91,
          source: p91,
          rows: 1,
          cols: 1,
        }, {
          src: p92,
          source: p92,
          rows: 1,
          cols: 1,
        },
        {
          src: p93,
          source: p93,
          rows: 1,
          cols: 1,
        },
        {
          src: p94,
          source: p94,
          rows: 1,
          cols: 1,
        },
        {
          src: p95,
          source: p95,
          rows: 1,
          cols: 1,
        },
        {
          src: p96,
          source: p96,
          rows: 1,
          cols: 1,
        },
        {
          src: p97,
          source: p97,
          rows: 1,
          cols: 1,
        },
        {
          src: p98,
          source: p98,
          rows: 1,
          cols: 1,
        },
        {
          src: p99,
          source: p99,
          rows: 1,
          cols: 1,
        },
        {
          src: p100,
          source: p100,
          rows: 1,
          cols: 1,
        },
        {
          src: p101,
          source: p101,
          rows: 1,
          cols: 1,
        },
        {
          src: p102,
          source: p102,
          rows: 1,
          cols: 1,
        },
        {
          src: p103,
          source: p103,
          rows: 1,
          cols: 1,
        },
        {
          src: p104,
          source: p104,
          rows: 1,
          cols: 1,
        },
        {
          src: p105,
          source: p105,
          rows: 1,
          cols: 1,
        },
        {
          src: p106,
          source: p106,
          rows: 1,
          cols: 1,
        },
        {
          src: p107,
          source: p107,
          rows: 1,
          cols: 1,
        },
        {
          src: p108,
          source: p108,
          rows: 1,
          cols: 1,
        },
        {
          src: p109,
          source: p109,
          rows: 1,
          cols: 1,
        },
        {
          src: p106,
          source: p107,
          rows: 1,
          cols: 1,
        },
        {
          src: p108,
          source: p108,
          rows: 1,
          cols: 1,
        },
        {
          src: p109,
          source: p109,
          rows: 1,
          cols: 1,
        },
        {
          src: p110,
          source: p110,
          rows: 1,
          cols: 1,
        },
        {
          src: p111,
          source: p111,
          rows: 1,
          cols: 1,
        },
        {
          src: p112,
          source: p112,
          rows: 1,
          cols: 1,
        },
        {
          src: p113,
          source: p113,
          rows: 1,
          cols: 1,
        },
        {
          src: p114,
          source: p114,
          rows: 1,
          cols: 1,
        },
        {
          src: p115,
          source: p115,
          rows: 1,
          cols: 1,
        },
        {
          src: p116,
          source: p116,
          rows: 1,
          cols: 1,
        },
        {
          src: p117,
          source: p17,
          rows: 1,
          cols: 1,
        },
        {
          src: p118,
          source: p118,
          rows: 1,
          cols: 1,
        },
        {
          src: p119,
          source: p119,
          rows: 1,
          cols: 1,
        },
        {
          src: p120,
          source: p120,
          rows: 1,
          cols: 1,
        },
        {
          src: p121,
          source: p121,
          rows: 1,
          cols: 1,
        },
        {
          src: p122,
          source: p122,
          rows: 1,
          cols: 1,
        },
        {
          src: p123,
          source: p123,
          rows: 1,
          cols: 1,
        },
        {
          src: p124,
          source: p124,
          rows: 1,
          cols: 1,
        }, {
          src: p125,
          source: p125,
          rows: 1,
          cols: 1,
        },
        {
          src: p126,
          source: p126,
          rows: 1,
          cols: 1,
        },
        {
          src: p127,
          source: p127,
          rows: 1,
          cols: 1,
        },
        {
          src: p128,
          source: p128,
          rows: 1,
          cols: 1,
        },
        {
          src: p129,
          source: p129,
          rows: 1,
          cols: 1,
        },
        {
          src: p130,
          source: p130,
          rows: 1,
          cols: 1,
        },
        {
          src: p131,
          source: p131,
          rows: 1,
          cols: 1,
        },
        {
          src: p132,
          source: p132,
          rows: 1,
          cols: 1,
        },
        {
          src: p133,
          source: p133,
          rows: 1,
          cols: 1,
        },
        {
          src: p134,
          source: p134,
          rows: 1,
          cols: 1,
        },
        {
          src: p135,
          source: p135,
          rows: 1,
          cols: 1,
        },
        {
          src: p136,
          source: p136,
          rows: 1,
          cols: 1,
        },
        {
          src: p137,
          source: p137,
          rows: 1,
          cols: 1,
        },
        {
          src: p138,
          source: p138,
          rows: 1,
          cols: 1,
        },
        {
          src: p139,
          source: p139,
          rows: 1,
          cols: 1,
        },
        {
          src: p140,
          source: p140,
          rows: 1,
          cols: 1,
        },
        {
          src: p141,
          source: p141,
          rows: 1,
          cols: 1,
        },
        {
          src: p142,
          source: p142,
          rows: 1,
          cols: 1,
        },
        {
          src: p143,
          source: p143,
          rows: 1,
          cols: 1,
        },
        {
          src: p144,
          source: p144,
          rows: 1,
          cols: 1,
        },
        {
          src: p145,
          source: p145,
          rows: 1,
          cols: 1,
        },
        {
          src: p146,
          source: p146,
          rows: 1,
          cols: 1,
        }, {
          src: p147,
          source: p147,
          rows: 1,
          cols: 1,
        },
        {
          src: p148,
          source: p148,
          rows: 1,
          cols: 1,
        },
        {
          src: p149,
          source: p149,
          rows: 1,
          cols: 1,
        },
        {
          src: p150,
          source: p150,
          rows: 1,
          cols: 1,
        },
        {
          src: p151,
          source: p151,
          rows: 1,
          cols: 1,
        },
        {
          src: p152,
          source: p152,
          rows: 1,
          cols: 1,
        },
        {
          src: p153,
          source: p153,
          rows: 1,
          cols: 1,
        },
        {
          src: p154,
          source: p154,
          rows: 1,
          cols: 1,
        },
        {
          src: p155,
          source: p155,
          rows: 1,
          cols: 1,
        },
        {
          src: p156,
          source: p156,
          rows: 1,
          cols: 1,
        },
        {
          src: p157,
          source: p157,
          rows: 1,
          cols: 1,
        },
        {
          src: p158,
          source: p158,
          rows: 1,
          cols: 1,
        },
        {
          src: p159,
          source: p159,
          rows: 1,
          cols: 1,
        },
        {
          src: p160,
          source: p160,
          rows: 1,
          cols: 1,
        },
        {
          src: p161,
          source: p161,
          rows: 1,
          cols: 1,
        },
        {
          src: p162,
          source: p162,
          rows: 1,
          cols: 1,
        },
        {
          src: p163,
          source: p163,
          rows: 1,
          cols: 1,
        },
        {
          src: p164,
          source: p164,
          rows: 1,
          cols: 1,
        },
        {
          src: p165,
          source: p165,
          rows: 1,
          cols: 1,
        },
        {
          src: p166,
          source: p166,
          rows: 1,
          cols: 1,
        },
        {
          src: p167,
          source: p167,
          rows: 1,
          cols: 1,
        },
        {
          src: p168,
          source: p168,
          rows: 1,
          cols: 1,
        }, {
          src: p169,
          source: p169,
          rows: 1,
          cols: 1,
        },
        {
          src: p170,
          source: p170,
          rows: 1,
          cols: 1,
        },
        {
          src: p171,
          source: p171,
          rows: 1,
          cols: 1,
        },
        {
          src: p172,
          source: p172,
          rows: 1,
          cols: 1,
        },
        {
          src: p173,
          source: p173,
          rows: 1,
          cols: 1,
        },
        {
          src: p174,
          source: p174,
          rows: 1,
          cols: 1,
        },
        {
          src: p175,
          source: p175,
          rows: 1,
          cols: 1,
        },
        {
          src: p176,
          source: p176,
          rows: 1,
          cols: 1,
        },
        {
          src: p177,
          source: p177,
          rows: 1,
          cols: 1,
        },
        {
          src: p178,
          source: p178,
          rows: 1,
          cols: 1,
        },
        {
          src: p179,
          source: p179,
          rows: 1,
          cols: 1,
        },
        {
          src: p180,
          source: p180,
          rows: 1,
          cols: 1,
        },
        {
          src: p181,
          source: p181,
          rows: 1,
          cols: 1,
        },
        {
          src: p182,
          source: p182,
          rows: 1,
          cols: 1,
        },
        {
          src: p183,
          source: p183,
          rows: 1,
          cols: 1,
        },
        {
          src: p184,
          source: p184,
          rows: 1,
          cols: 1,
        },
        {
          src: p185,
          source: p185,
          rows: 1,
          cols: 1,
        },
        {
          src: p186,
          source: p186,
          rows: 1,
          cols: 1,
        },
        {
          src: p187,
          source: p187,
          rows: 1,
          cols: 1,
        },
        {
          src: p188,
          source: p188,
          rows: 1,
          cols: 1,
        },
        {
          src: p189,
          source: p189,
          rows: 1,
          cols: 1,
        },
        {
          src: p190,
          source: p190,
          rows: 1,
          cols: 1,
        },
        {
          src: p191,
          source: p191,
          rows: 1.5,
          cols: 1,
        }, {
          src: p192,
          source: p192,
          rows: 1.5,
          cols: 1,
        },
        {
          src: p193,
          source: p193,
          rows: 1.5,
          cols: 1,
        },
        {
          src: p194,
          source: p194,
          rows: 1,
          cols: 1,
        },
        {
          src: p195,
          source: p195,
          rows: 1,
          cols: 1,
        },
        {
          src: p196,
          source: p196,
          rows: 1,
          cols: 1,
        },
        {
          src: p197,
          source: p197,
          rows: 1,
          cols: 1,
        },
        {
          src: p198,
          source: p198,
          rows: 1,
          cols: 1,
        },
        {
          src: p199,
          source: p199,
          rows: 1,
          cols: 1,
        },
        {
          src: p200,
          source: p200,
          rows: 1,
          cols: 1,
        },
        {
          src: p201,
          source: p201,
          rows: 2,
          cols: 1,
        },
        {
          src: p202,
          source: p202,
          rows: 1,
          cols: 1,
        },
        {
          src: p203,
          source: p203,
          rows: 1,
          cols: 1,
        },
        {
          src: p204,
          source: p204,
          rows: 1,
          cols: 1,
        },
        {
          src: p205,
          source: p205,
          rows: 1,
          cols: 1,
        },
        {
          src: p206,
          source: p206,
          rows: 1,
          cols: 1,
        },
        {
          src: p207,
          source: p207,
          rows: 1,
          cols: 1,
        },
        {
          src: p208,
          source: p208,
          rows: 2,
          cols: 1,
        },
        {
          src: p209,
          source: p209,
          rows: 1,
          cols: 1,
        },
        
        {
          src: p210,
          source: p210,
          rows: 1,
          cols: 1,
        }, {
          src: p211,
          source: p211,
          rows: 1,
          cols: 1,
        },
        {
          src: p212,
          source: p212,
          rows: 1,
          cols: 1,
        },
        {
          src: p213,
          source: p213,
          rows: 1,
          cols: 1,
        },
        {
          src: p214,
          source: p214,
          rows: 1,
          cols: 1,
        },
        {
          src: p215,
          source: p215,
          rows: 1,
          cols: 1,
        },
        {
          src: p216,
          source: p216,
          rows: 1,
          cols: 1,
        },
        {
          src: p217,
          source: p217,
          rows: 1,
          cols: 1,
        },
        {
          src: p218,
          source: p218,
          rows: 1,
          cols: 1,
        },
        {
          src: p219,
          source: p219,
          rows: 1,
          cols: 1,
        },
        {
          src: p220,
          source: p220,
          rows: 1,
          cols: 1,
        },
        {
          src: p221,
          source: p221,
          rows: 1,
          cols: 1,
        },
        {
          src: p222,
          source: p222,
          rows: 1,
          cols: 1,
        },
        {
          src: p223,
          source: p223,
          rows: 1,
          cols: 1,
        },
        {
          src: p224,
          source: p224,
          rows: 1,
          cols: 1,
        }, {
          src: p225,
          source: p225,
          rows: 1,
          cols: 1,
        },
        {
          src: p226,
          source: p226,
          rows: 1,
          cols: 1,
        },
        {
          src: p227,
          source: p227,
          rows: 1,
          cols: 1,
        },
        {
          src: p228,
          source: p228,
          rows: 1,
          cols: 1,
        },
        {
          src: p229,
          source: p229,
          rows: 1,
          cols: 1,
        },
        {
          src: p230,
          source: p230,
          rows: 1,
          cols: 1,
        },
        {
          src: p231,
          source: p231,
          rows: 1,
          cols: 1,
        },
        {
          src: p232,
          source: p232,
          rows: 1,
          cols: 1,
        },
        {
          src: p233,
          source: p233,
          rows: 1,
          cols: 1,
        },
        {
          src: p234,
          source: p234,
          rows: 1,
          cols: 1,
        },
        {
          src: p235,
          source: p235,
          rows: 1,
          cols: 1,
        },
        {
          src: p236,
          source: p236,
          rows: 1,
          cols: 1,
        },
        {
          src: p237,
          source: p237,
          rows: 1,
          cols: 1,
        },
        {
          src: p238,
          source: p238,
          rows: 1,
          cols: 1,
        },
        {
          src: p239,
          source: p239,
          rows: 1,
          cols: 1,
        },
        {
          src: p240,
          source: p240,
          rows: 1,
          cols: 1,
        },
        {
          src: p241,
          source: p241,
          rows: 1,
          cols: 1,
        },
        {
          src: p242,
          source: p242,
          rows: 1,
          cols: 1,
        },
        {
          src: p243,
          source: p243,
          rows: 1,
          cols: 1,
        },
        {
          src: p244,
          source: p244,
          rows: 1,
          cols: 1,
        },
        {
          src: p245,
          source: p245,
          rows: 1,
          cols: 1,
        },
        {
          src: p246,
          source: p246,
          rows: 1,
          cols: 1,
        }, {
          src: p247,
          source: p247,
          rows: 1,
          cols: 1,
        },
        {
          src: p248,
          source: p248,
          rows: 1,
          cols: 1,
        },
        {
          src: p249,
          source: p249,
          rows: 1,
          cols: 1,
        },
        {
          src: p250,
          source: p250,
          rows: 1,
          cols: 1,
        },
        {
          src: p251,
          source: p251,
          rows: 2,
          cols: 1,
        },
        {
          src: p252,
          source: p252,
          rows: 2,
          cols: 1,
        },
        {
          src: p253,
          source: p253,
          rows: 2,
          cols: 1,
        },
        {
          src: p254,
          source: p254,
          rows: 2,
          cols: 1,
        },
        {
          src: p255,
          source: p255,
          rows: 1,
          cols: 1,
        },
        {
          src: p256,
          source: p256,
          rows: 1,
          cols: 1,
        },
        {
          src: p257,
          source: p257,
          rows: 1,
          cols: 1,
        },
        {
          src: p258,
          source: p258,
          rows: 1,
          cols: 1,
        },
        {
          src: p259,
          source: p259,
          rows: 1,
          cols: 1,
        },
        {
          src: p260,
          source: p260,
          rows: 1,
          cols: 1,
        },
        {
          src: p261,
          source: p261,
          rows: 1,
          cols: 1,
        },
        {
          src: p262,
          source: p262,
          rows: 1,
          cols: 1,
        },
        {
          src: p263,
          source: p263,
          rows: 1,
          cols: 1,
        },
        {
          src: p264,
          source: p264,
          rows: 1,
          cols: 1,
        },
        {
          src: p265,
          source: p265,
          rows: 1,
          cols: 1,
        },
        {
          src: p266,
          source: p266,
          rows: 1,
          cols: 1,
        },
        {
          src: p267,
          source: p267,
          rows: 1,
          cols: 1,
        },
        {
          src: p268,
          source: p268,
          rows: 1,
          cols: 1,
        },
        {
          src: p269,
          source: p269,
          rows: 1,
          cols: 1,
        },
        {
          src: p270,
          source: p270,
          rows: 1,
          cols: 1,
        },
        {
          src: p271,
          source: p271,
          rows: 1,
          cols: 1,
        },
        {
          src: p272,
          source: p272,
          rows: 1,
          cols: 1,
        },
        {
          src: p273,
          source: p273,
          rows: 1,
          cols: 1,
        },
        {
          src: p274,
          source: p274,
          rows: 1,
          cols: 1,
        },
      
        {
          src: p276,
          source: p276,
          rows: 1.5,
          cols: 1,
        },
        {
          src: p277,
          source: p277,
          rows: 1.5,
          cols: 1,
        },
        {
          src: p278,
          source: p278,
          rows: 1.5,
          cols: 1,
        },
        {
          src: p279,
          source: p279,
          rows: 1,
          cols: 1,
        },
        {
          src: p280,
          source: p280,
          rows: 1,
          cols: 1,
        },
         {
          src: p281,
          source: p281,
          rows: 1,
          cols: 1,
        },
        {
          src: p282,
          source: p282,
          rows: 1,
          cols: 1,
        },
        {
          src: p283,
          source: p283,
          rows: 1,
          cols: 1,
        },
        {
          src: p284,
          source: p284,
          rows: 1,
          cols: 1,
        },
        {
          src: p285,
          source: p285,
          rows: 1,
          cols: 1,
        },
        {
          src: p286,
          source: p286,
          rows: 1,
          cols: 1,
        },
        {
          src: p287,
          source: p287,
          rows: 1,
          cols: 1,
        },
        {
          src: p288,
          source: p288,
          rows: 1,
          cols: 1,
        },
        {
          src: p289,
          source: p289,
          rows: 1,
          cols: 1,
        },
        {
          src: p275,
          source: p275,
          rows: 1,
          cols: 1,
        }
    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Box>
      <Box display={'flex'} justifyContent={'flex-end'} marginBottom={2}>
        {/* <Button
          color="primary"
          size="large"
          endIcon={
            <svg
              width={16}
              height={16}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          }
          onClick={() => openLightbox(0)}
        >
          Open the gallery
        </Button> */}
          <Container
      
      >
        <Box>
          <Box >
            <Typography
              variant="h2"
              align={'center'}
           
            >
              Photo Gallery - 2023-24
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="h4"
              align={'center'}
              sx={{
                fontWeight: 700,
                color: theme.palette.common.white,
              }}
            >
              
            </Typography>
          </Box>
        </Box>
      </Container>
      </Box>
      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
        
      )}
    </Box>
  );
};

export default GalleryImages2023;